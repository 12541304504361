<template>
  <v-card :elevation="elevation">
    <v-card-title class="justify-center">
      {{ title }}
    </v-card-title>
    <v-card-text class="px-2 px-sm-5">
      <Bar
        :height="300"
        :chart-data="chartData"
        :chart-options="chartOptions"
        :left-y-axis="leftYAxis"
        :right-y-axis="rightYAxis"
        :stacked="stacked"
        :show-data-label="showDataLabel"
        :show-legend="showLegend"
      />
      <div
        v-if="loading"
        class="mt-1"
      >
        <v-progress-linear
          indeterminate
        />
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import 'chart.js/auto'
import { Chart } from 'chart.js'
import { Bar } from 'vue-chartjs/legacy'
import ChartjsPluginWatermark from 'chartjs-plugin-watermark'
import ChartjsPluginDatalabels from 'chartjs-plugin-datalabels'

Chart.register(ChartjsPluginWatermark)
Chart.register(ChartjsPluginDatalabels)

export default {

  components: {
    Bar,
  },

  props: {
    title: {
      required: true,
      type: String,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
      type: Array,
    },
    indicators: {
      required: true,
      type: Array,
    },
    times: {
      required: true,
      type: Array,
    },
    showLegend: {
      type: Boolean,
      default: true,
    },
    showDataLabel: {
      type: Boolean,
      default: true,
    },
    elevation: {
      type: Number,
      default: undefined,
    },
  },

  data() {
    return {
      types: {},
      units: {},
      colors: {},
      leftYAxis: {},
      rightYAxis: {},
      stacked: false,
    }
  },

  computed: {
    chartData() {
      let datasets = []
      for (let i = 0; i < this.data.length; i += 1) {
        const { id, label, values } = this.data[i]
        const plot = {
          label,
          type: this.types[id] === 'stacked-bar' ? 'bar' : this.types[id],
          yAxisID: this.units[id] === this.leftYAxis.unit ? 'L' : 'R',
          order: this.types[id] === 'line' ? 0 : 1, // show line in the front
          borderColor: this.colors[id],
          backgroundColor: this.colors[id],
          pointBorderColor: this.colors[id],
          pointBackgroundColor: this.colors[id],
          fill: false,
          pointBorderWidth: 5,
          data: values || [],
        }
        datasets = [...datasets, plot]
      }

      return {
        labels: this.labels,
        datasets,
      }
    },

    chartOptions() {
      return {
        responsive: true,
        maintainAspectRatio: false,
        hover: { mode: null },
        tension: 0.4,
        layout: {
          padding: {
            top: 20,
          },
        },
        scales: {
          x: { stacked: this.stacked },
          L: this.getYAxesConfig(this.leftYAxis.unit, this.leftYAxis.stacked, false),
          R: this.getYAxesConfig(this.rightYAxis?.unit, this.rightYAxis?.stacked, true),
        },
        watermark: {
          image: require('@/assets/images/logos/logo.png'),

          width: 170,
          height: 47,
          opacity: 0.2,

          alignX: 'middle',
          alignY: 'middle',

          position: 'front',
        },
        plugins: {
          legend: {
            display: this.showLegend,
            position: 'bottom',
            labels: {
              boxWidth: 25,
            },
          },
          datalabels: {
            display: this.showDataLabel ? 'auto' : false,
            anchor: 'bottom',
            clamp: true,
            color: 'white',
            backgroundColor(ctx) {
              return ctx.dataset.borderColor || ctx.dataset.backgroundColor
            },
            borderColor: 'white',
            borderWidth: 0,
            borderRadius: 3,
            padding: {
              top: 2,
              bottom: 0,
              right: 3,
              left: 3,
            },
          },
        },
      }
    },

    labels() {
      return this.times.map(t => {
        const q = t.substring(0, 1)
        const y = t.substring(3, 5)

        return `Q${q}/${y}`
      })
    },
  },

  created() {
    this.indicators.forEach(({ id, unit, options }) => {
      this.units = { ...this.units, [id]: unit }
      this.types = { ...this.types, [id]: options.type }
      this.colors = { ...this.colors, [id]: options.color }

      this.stacked = (options.type === 'stacked-bar') ? true : this.stacked

      if (Object.keys(this.leftYAxis).length === 0) {
        this.leftYAxis = {
          unit,
          stacked: options.type === 'stacked-bar',
        }
      }
      if (Object.keys(this.rightYAxis).length === 0 && this.leftYAxis.unit !== unit) {
        this.rightYAxis = {
          unit,
          stacked: options.type === 'stacked-bar',
        }
      }
    })
  },

  methods: {
    getYAxesConfig(unit, isStacked, isRightAxis) {
      const labels = {
        none: '',
        one: this.$t('one_vnd'),
        thousand: this.$t('thousand_vnd'),
        million: this.$t('million_vnd'),
        billion: this.$t('billion_vnd'),
      }

      return {
        id: isRightAxis ? 'R' : 'L',
        position: isRightAxis ? 'right' : 'left',
        display: !((isRightAxis && !unit)),
        ticks: (unit === 'percentage'
          ? {
            beginAtZero: true,
            callback(value) {
              return `${value}%`
            },
          }
          : {
            beginAtZero: true,
          }
        ),
        title: {
          display: unit !== 'percentage',
          text: labels[unit] || '',
        },
        stacked: isStacked,
      }
    },
  },
}
</script>
